*,
*::before,
*::after {
   box-sizing: border-box;
}

* {
   margin: 0;
   padding: 0;
   font: inherit;
}

html,
body {
   height: 100%;
}

html {
   color-scheme: dark;
}

html:focus-within {
   scroll-behavior: smooth;
}

body {
   color: white;
   text-rendering: optimizeSpeed;
   line-height: 1.5;
   font-size: 0.85em;
   font-family: 'Poppins', sans-serif;
   overflow: hidden;
}

div,
section,
header,
article,
footer,
main,
nav,
form,
button {
   display: flex;
   gap: 0.5em;
}

button {
   align-items: center;
   justify-content: center;
}

ul,
ol {
   list-style: none;
}

img,
picture,
svg {
   display: block;
   max-width: 100%;
   height: 100%;
   object-fit: contain;
}

button,
a,
label {
   cursor: pointer;
   background-color: transparent;
   color: inherit;
   border: none;
   text-decoration: none;
   white-space: nowrap;
}

label,
li {
   white-space: nowrap;
   line-height: 1.2;
}



input,
select,
textarea {
   outline: none;
   border: none;
   background: none;
   cursor: auto;
}

select {
   cursor: pointer;
}



dialog {
   margin: auto;
   border: none;
   user-select: none;
   visibility: unset;

   &:focus-visible {
      outline: none;

   }

   button:focus-visible {
      border-radius: none;
      border: none;
      outline: none;
   }
}

dialog::backdrop {
   background: rgb(0 0 0 / 0.4);
}



::-webkit-scrollbar {
   width: 0.75em;
   height: 0.75em;
}

::-webkit-scrollbar-track {
   background: $c1;
}

::-webkit-scrollbar-thumb {
   background: $orange-light;
   border-radius: 5px;
}

::-webkit-calender-picker-indicator {
   background: white;
   padding: 0.5em;
   cursor: pointer;
   border-radius: 5px;
}
.App {
   width: 100vw;
   height: 100vh;
   gap: 0;
   overflow-x: hidden;
   flex-direction: column;
}

.icon {
   width: 25px;
}

.banner {
   width: 100%;
}

.cmp {
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   gap: 0;
   background-position: bottom;
}


.header {
   background-image: url('../assets/imgs/1.png');
   // max-height: 900px;



   .img {
      flex-basis: 60%;
      justify-content: end;
      align-items: center;
      // margin-right: -15%;
      position: relative;

      img {
         height: 80%;
         position: absolute;
         top: 5%;
         right: -5%;
      }
   }

   section.left {
      flex-direction: column;
      flex-basis: 40%;
      gap: 0;
      min-height: 50vw;

      .txt {
         letter-spacing: 1px;

         height: 60%;
         flex-direction: column;
         align-items: center;
         font-family: Merriweather;
         font-size: 2.5vw;
         font-weight: 900;
         color: $gold1;
         text-align: center;
         white-space: nowrap;

         .rec {
            height: 3.5px;
            width: 20vw;
            background: $gradient;
         }
      }

      .logo {
         height: 40%;

         @media (min-width:1000px) {
            height: 30%;
         }

         @media (min-width:1400px) {
            height: 25%;
         }

         img {
            padding: 1em 1.5em;
            // max-height: 150px;

            @media (max-width:500px) {
               padding: 0.5em 0.75em;
            }
         }
      }



   }



}


.main {
   background: url('../assets/imgs/4.png');
   flex-direction: column;

   .img {
      position: absolute;
      top: 30%;
      bottom: 15%;
      left: -15%;
      width: 66.66%;

      img {
         flex: 1;
      }
   }

   .title {
      width: 100%;
      position: relative;
      min-height: 15%;

      .txt {
         position: absolute;
         width: 100%;
         font-size: 2.5vw;
         color: $gold1;
         background: white;
         padding: 0.25em;
         text-align: center;
         flex-direction: column;
         gap: 0;
         top: 0.5em;
         font-family: Merriweather;
      }
   }

   .sub-title {
      flex-direction: column;
      color: white;
      white-space: nowrap;
   }


   .cards {
      flex-direction: column;
      gap: 5%;
      z-index: 9999;
      color: black;
      font-family: Poppins;
      width: 90%;
      max-width: 1000px;
      margin: auto;
      margin-bottom: 10%;
      font-size: 1.75vw;

      @media (min-width: 1350px) {
         font-size: 1.35vw;
      }
   }

   .row {
      justify-content: end;
      height: 33.33%;
      gap: 5%;

      button {
         text-align: left;
         white-space: pre-wrap;
         align-items: flex-start;

         &:hover {
            opacity: 0.75;
         }
      }
   }

   .card {
      width: 33%;
      aspect-ratio: 3/2;
      display: flex;
      flex-direction: column;
      padding: 1em;
      border-radius: 20px;
      background: $gold1;
      box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.5);



      b {
         font-weight: 700;
      }

      a:hover {
         opacity: 0.75;
      }
   }


   .ribbon {
      color: black;
      flex-direction: column;
      width: 65%;
      align-self: end;
      background-color: #3498db;
      clipPath: polygon(15% 0%, 100% 0%, 100% 100%, 1% 100%);
      gap: 0;
      padding: 1em;
      font-size: 1.7vw;
      font-family: Merriweather;
      text-align: center;
      background: linear-gradient(90deg, rgb(231, 184, 102) 15%, #FEF8C8 25%, rgb(231, 184, 102) 50%, #FEF8C8 150%);
   }

   .bottom {
      padding: 7.5%;
      background: black;
      flex-direction: column;
      gap: 2em;
      font-size: 2.5vw;

      @media (min-width: 1000px) {
         font-size: 2vw;
      }

      .txt1 {
         flex-direction: column;
         align-self: end;
         font-family: Merriweather;

      }

      h1 {
         font-family: Merriweather;
         font-weight: 600;
         color: $gold1;
         text-align: center;
      }
   }
}

.video {
   background: url('../assets/imgs/4.png');
   justify-content: center;
   align-items: center;

   video {
      width: 100%;
      border-radius: 10px;
   }
}

.ari {
   background: white;
   justify-content: center;
   align-items: center;
   padding: 2em 1em 0 1em;

   img {
      // height: 70%;
   }

   video {
      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
      aspect-ratio: 295 / 165;
      width: 90%;
      max-width: 800px;
   }

   >* {
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      // @media (max-width:900px) {
      //    max-height: 250px;
      //    max-width: 450px;
      // }
      // @media (max-width:900px) {
      //    max-height: 250px;
      //    max-width: 450px;
      // }

      // @media (max-width:700px) {
      //    max-height: 200px;
      //    max-width: 360px;
      // }

      // @media (max-width:515px) {
      //    max-height: 125px;
      //    max-width: 225px;
      // }
   }
}


.footer {
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: white;
   gap: 0.5em !important;
   color: black;
   font-size: 2.85vw;
   padding: 1em;
   font-family: Merriweather;

   .item {
      display: flex;
      gap: 0.5em;
   }

   img {
      margin-top: 2em;
      width: 25%;
   }

   @media (min-width: 1000px) {
      font-size: 1.5vw;
   }

   b {
      font-weight: 600;
   }

   p:hover {
      transform: scale(1.01);
   }

   .icon {
      @media (max-width:700px) {
         width: 15px;
      }
   }
}

.pdf {
   flex-direction: column;
   width: 90%;
   min-height: 82.5vh;
   max-width: 850px;
   margin: 1em auto;
   overflow-y: scroll;
   flex: 1;

   @media (max-width:500px) {
      min-height: 60vh;
   }

   .img {
      width: 100%;
      height: 100%;
      margin: auto;
   }

}

@keyframes tilt-shake {

   0%,
   20%,
   100% {
      transform: rotate(0deg);
   }

   10% {
      transform: rotate(5deg);
   }

   15% {
      transform: rotate(-10deg);
   }

   //  90% {
   //    transform: rotate(5deg);
   //  }
}

@keyframes grow {
   0% {
      transform: scale(1);
   }

   50% {
      transform: scale(1.1);
   }

   100% {
      transform: scale(1);
   }
}

.svg-animate {
   display: flex;
   animation: tilt-shake 2s infinite;
   animation-delay: 5s;
   align-items: center;

   &:hover {
      animation: none;
   }
}

.footer .contact-us .item svg {
   transition: transform 0.3s;

   &.grow {
      animation: grow 2s infinite;
   }
}

.footer .contact-us .item:hover svg {
   transform: rotate(5deg);
   animation: tilt-shake 2s;
}
@keyframes spin2 {
   0% {
      transform: translate(-50%, -50%) rotate(0deg);
   }

   100% {
      transform: translate(-50%, -50%) rotate(360deg);
   }
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}


@keyframes rotateY {
   0% {
      transform: rotateY(0deg);
   }

   100% {
      transform: rotateY(180deg);
   }
}

@keyframes show_slide {
   0% {
      transform: translateX(100%);
   }

   40% {
      transform: translateX(-10%);
   }

   80% {
      transform: translateX(0%);
   }

   100% {
      transform: translateX(-10px);
   }
}

@keyframes hide_slide {
   0% {
      transform: translateX(-10px);
   }

   40% {
      transform: translateX(0%);
   }

   80% {
      transform: translateX(-10%);
   }

   100% {
      transform: translateX(100%);
   }
}

@keyframes shimmy {
   0% {
      background-position: 0% 0, 0 0, 120px 0, 120px 40px, 120px 80px, 120px 120px;
   }

   100% {
      background-position: 100% 0, 0 0, 120px 0, 120px 40px, 120px 80px, 120px 120px;
   }
}

@keyframes lqt {

   0%,
   100% {
      background-image: linear-gradient($orange-dark 40px, transparent 0);
      background-position: 0% 0px;
   }

   50% {
      background-image: linear-gradient($orange-dark 40px, transparent 0);
      background-position: 0% 40px;
   }

   50.1% {
      background-image: linear-gradient($orange-dark 40px, transparent 0);
      background-position: 0% -40px;
   }
}

@keyframes lqb {
   0% {
      background-image: linear-gradient($orange-dark 40px, transparent 0);
      background-position: 0 40px;
   }

   100% {
      background-image: linear-gradient($orange-dark 40px, transparent 0);
      background-position: 0 -40px;
   }
}

@keyframes spinx {

   0%,
   49% {
      transform: rotate(0deg);
      background-position: 50% 36px;
   }

   51%,
   98% {
      transform: rotate(180deg);
      background-position: 50% 4px;
   }

   100% {
      transform: rotate(360deg);
      background-position: 50% 36px;
   }
}
@keyframes grow {
   0% {
      transform: scale(1);
   }

   100% {
      transform: scale(1.2);
   }
}
@keyframes opencover {
   
   0% {
      transform: rotateZ(0deg);
   }

   100% {
      transform: rotateZ(33deg);
   }
}



.form {
   flex-direction: column;
   background: url('../assets/imgs/1.png');
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;

   .btn {
      min-width: 125px;
      min-height: 35px;
      margin-top: 1em;
      border-radius: 25px;
      background: $gold1;
      color: white;
      padding: 0.5em 0.75em;
      align-self: center;
      font-size: 1.1em;
      font-weight: 600;
      gap: 0;

      &:hover {
         opacity: 0.9;
      }

      svg {
         animation: upDown 1.5s infinite ease-in-out;

      }

      @keyframes upDown {
         0%, 100% {
           transform: translateY(-0.5px);
         }
         50% {
           transform: translateY(2.5px);
         }
       }
   }

   form {
      margin: 2em auto;
      padding: 2em;
      background: white;
      border-radius: 10px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      font-family: Merriweather;
      font-size: 1.5vw;
      color: black;

      @media (max-width:500px) {
         flex-direction: column;
      }
   }

   form .group {
      display: flex;
      flex-direction: column;
   }

   form label {
      margin-bottom: 0.5em;
      font-weight: 600;
   }

   form input {
      padding: 0.25em 0.75em;
      border: 1px solid $c8;
      border-radius: 5px;
      color: $c8;
      min-height: 25px;
   }

   form button {
      margin-top: 1em;
      min-width: 125px;
      padding: 0.25em 0.75em;

      background: $gold1;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 1.5vw;
      align-self: flex-end;
      min-height: 25px;

      @media (min-width:500px) {}
   }

   form button:hover {
      opacity: 0.75;
   }
}